<template>
    <div class="order-detail fadeInRight">
        <div class="title-box"><span class="m-title">详细信息</span>
            <el-form inline ref="orderDetailForm"  class="right themed-form search-form" style="height: 40px;line-height: 40px;">
                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>
        <div class="content-box">
             <el-table
                    ref='table'
                    stripe
                    :max-height="maxTableHeight" 
                    :data="tableData"
                    border
                    style="width: 100%"
                    tooltip-effect="dark"
                    >
                <el-table-column
                    type="index"
                    min-width="50">
                </el-table-column>
                <el-table-column
                        prop="setMealName"
                        label="套餐名称"
                        min-width="98">
                    </el-table-column>
                    <el-table-column
                        prop="accountingDate"
                        label="核算周期"
                        min-width="98">
                    </el-table-column>
                    <el-table-column
                        prop="accountingTime"
                        label="核算时间"
                        min-width="98">
                    </el-table-column>
                    <el-table-column
                        prop="orderCount"
                        label="订单总数"
                        min-width="98">
                    </el-table-column>

                    <el-table-column
                        prop="orderMoney"
                        label="订单总额"
                        min-width="98">
                    </el-table-column>
                    <el-table-column
                        prop="setMealMoney"
                        label="套餐总额"
                        min-width="98">
                    </el-table-column>
                    <el-table-column
                        label="代理差价"
                        min-width="98">
                        <template slot-scope="scope">
                        <span>{{(scope.row.orderMoney - scope.row.setMealMoney)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="interestRate"
                        label="返点率"
                        min-width="98">
                    </el-table-column>
                    <el-table-column
                        prop="accountingMoney"
                        label="返现金额"
                        min-width="98">
                    </el-table-column>

                    <el-table-column
                        label="秒返金额"
                        min-width="98">
                        <template slot-scope="scope">
                        <span>{{parseFloat(scope.row.mfMoney).toFixed(2)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="可提现金额"
                        min-width="98">
                        <template slot-scope="scope">
                        <span>{{parseFloat(scope.row.txMoney).toFixed(2)}}</span>
                        </template>
                    </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>
<script>
import { orderDetailList,exportOrderDetail } from "@/api/order.js";
import resize from '@/mixins/resize.js'
export default {
    name:'orderDetail',
    mixins:[resize],
    data(){
        return {
            page:1,
            pageSize:15,
            total:0,
            tableData:[],
        }
    },
    created(){
        this.orderMoneyId = this.$route.query.orderMoneyId
        console.log(this.orderMoneyId,102)
        this.getOrderDetailList()
    },
    methods:{
        getOrderDetailList(){
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page,
                orderMoneyId:this.orderMoneyId
            }
            orderDetailList(params).then(res=>{
                this.tableData = res.rows
                this.total = res.count
            })

        },
        exportExcel(){
            exportOrderDetail().then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        onPageChange(page){
				this.page = page
				this.getOrderDetailList()
			},
		handleSizeChange(size){
				this.pageSize = size
				this.getOrderDetailList()
			},
    }
}
</script>
<style>
    .order-detail .el-form-item__label{
        vertical-align: top;
    }
</style>